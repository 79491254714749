import React, { useState, useEffect } from "react"
import { Button, Modal } from "react-bootstrap"
import Web3 from "web3"
import { PageProps } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Home from "../../components/pages/home"
import BionicOutlierTribe from '../../../abis/mainnet/BionicOutlierTribe.json'

const MAINNET_NETWORK_ID = 1;

const HomePage: React.FC<PageProps> = pageProps => {
  // Pull web3 information - might be worth pulling this out so abstracted further from the page.
  // A HOC or putting it in Layout might be helpful.
  const [account, setAccount] = useState<string | null>(null)
  const [contract, setContract] = useState<any | null>(null)
  const [shouldShowError, setShouldShowError] = useState<boolean>(false);

  // web3
  const web3 = new Web3(Web3.givenProvider || "http://localhost:8545")

  const getAndSetAccount = async () => {
    // Get customer account (wallet address) from meta mask
    const newAccounts = await web3.eth.getAccounts()
    const newAccount = newAccounts[0] as string
    setAccount(newAccount)
  }

  useEffect(() => {
    const setupWeb3Info = async () => {
      // Get contract from the network
      const networkId = await web3.eth.net.getId()
      if (networkId !== MAINNET_NETWORK_ID) {
        setShouldShowError(true);
      }

      const networkData = BionicOutlierTribe.networks[networkId]
      if (networkData) {
        const abi = BionicOutlierTribe.abi
        const contractAddress = networkData.address
        const contract = new web3.eth.Contract(abi, contractAddress)
        setContract(contract)
      }

      getAndSetAccount();
    }
    // Update the document title using the browser API
    setupWeb3Info()
  }, []);

  return (
    <Layout
      pageProps={pageProps}
      accountAddress={account}
      contract={contract}
      getAndSetAccount={getAndSetAccount}
      shouldShowNavbar={true}
      shouldShowFooter={true}
    >
      <SEO title="Home" />
      <Home contract={contract} accountAddress={account} getAndSetAccount={getAndSetAccount}/>
      <Modal
        show={shouldShowError}
        onHide={() => setShouldShowError(false)}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Network Error</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          You're not connected to the Ethereum mainnet. Make sure you are connected before minting!
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShouldShowError(false)}
            className="bots-dismiss-button"
          >
            Dismiss
          </Button>
        </Modal.Footer>
      </Modal>
    </Layout>
  )
}

export default HomePage
