import React, { useState }  from "react"
import { Button, Modal } from "react-bootstrap"
import MetaMaskOnboarding from '@metamask/onboarding'

type MetaMaskConnectButtonProps = {
  getAndSetAccount: Function,
  classNames: string,
}
  
// TODO: should trigger a state change so that address repopulates and all necessary info.
// Similar issues on MintButton and we should probably bubble up state to page level to make it easy.
const MetaMaskConnectButton: React.FC<MetaMaskConnectButtonProps> = ({ getAndSetAccount, classNames }) => {
    const [shouldShowError, setShouldShowError] = useState<boolean>(false);

    // Check the ethereum binding on the window object to see if it's installed
    const { ethereum } = (typeof window !== 'undefined') ? window : { ethereum: null };
    const isMetaMaskInstalled = ethereum && ethereum.isMetaMask;
    const buttonText = isMetaMaskInstalled ? "Connect to MetaMask" : "Install MetaMask";

    const onClickConnect = async () => {
      try {
        // This will open the MetaMask UI
        await ethereum.request({ method: 'eth_requestAccounts' });
        getAndSetAccount();
      } catch (error) {
        setShouldShowError(true);
        console.error(error);
      }
    };

    const onClickInstall = () => {
      const forwarderOrigin = 'http://localhost:8000';
      const onboarding = new MetaMaskOnboarding({ forwarderOrigin });
      onboarding.startOnboarding();
    };

    return (
      <>
        <Modal
          show={shouldShowError}
          onHide={() => setShouldShowError(false)}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Error Connecting to MetaMask</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            An error occurred while connecting to MetaMask. Please try again and post in the Discord if this issue persists.
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setShouldShowError(false)}
              className="bot-dismiss-button"
            >
              Dismiss
            </Button>
          </Modal.Footer>
        </Modal>

        <Button
          variant="outline-primary"
          onClick={isMetaMaskInstalled ? onClickConnect : onClickInstall}
          className={classNames}
          style={{width: "100%"}}
        >
          {buttonText}
        </Button>
      </>
    )
}

export default MetaMaskConnectButton
