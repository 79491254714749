import React, { FormEvent, useEffect, useState } from "react"
import { Container, Row, Col, Image, Button, Form } from "react-bootstrap"
import { Contract } from "web3-eth-contract"
import { isMobile } from "../../../utils/device"
import { FaTwitter } from 'react-icons/fa';
import MetaMaskConnectButton from "../../buttons/MetaMaskConnectButton";
import BotLogo from "../../../images/BOTLogo.png"
import BotWelcomeHeader from "../../../images/BOTWelcomeHeader.jpg"
import BotGold from "../../../images/BOTGold.mp4"
import Bot2 from "../../../images/bot2.jpg"
import Bot3 from "../../../images/bot3.jpg"
import Bot4 from "../../../images/bot4.jpg"
import Bot5 from "../../../images/bot5.jpg"
import Bot6 from "../../../images/bot6.jpg"
import Crystal1 from "../../../images/Crystal1.png"
import Crystal2 from "../../../images/Crystal2.png"
import Crystal3 from "../../../images/Crystal3.png"
import Crystal4 from "../../../images/Crystal4.png"
import Crystal5 from "../../../images/Crystal5.png"
import Team1 from "../../../images/Team1.png"
import Team2 from "../../../images/Team2.png"
import Team3 from "../../../images/Team3.png"
import Team4 from "../../../images/Team4.png"
import Team5 from "../../../images/Team5.png"
import Team6 from "../../../images/Team6.png"
import Badge1 from "../../../images/p1.png"
import Badge2 from "../../../images/p2.png"
import Badge3 from "../../../images/p3.png"
import Badge4 from "../../../images/p4.png"
import Badge5 from "../../../images/p5.png"
import Badge6 from "../../../images/p6.png"
import Badge7 from "../../../images/p7.png"
import Badge8 from "../../../images/p8.png"
import Badge9 from "../../../images/p9.png"
import Badge10 from "../../../images/p10.png"
import Badge11 from "../../../images/p11.png"
import Badge12 from "../../../images/pe1.png"
import Badge13 from "../../../images/pe2.png"
import Badge14 from "../../../images/pe3.png"
import Badge15 from "../../../images/pe4.png"
import Badge16 from "../../../images/pe5.png"
import BOTWelcome from "../../../images/BOTWelcome.png"
import BOTGif from "../../../images/BOT.gif"
import RoadmapBot1 from "../../../images/RoadmapBot1.png"
import RoadmapBot2 from "../../../images/RoadmapBot2.png"

const styles = require("./home.module.scss")

type HomeProps = {
  accountAddress: string | null,
  contract: Contract,
  getAndSetAccount: Function,
}

/**
 * Home page for BOT.
 */
const Home: React.FC<HomeProps> = ({ accountAddress, contract, getAndSetAccount }) => {
  // General state
  const [isLoading, setIsLoading] = useState(false);
  const [shouldShowError, setShouldShowError] = useState<boolean>(false);
  const [transactionUrl, setTransactionUrl] = useState("");

  // Contract state
  const [hasPresaleStarted, setHasPresaleStarted] = useState(false);
  const [hasSaleStarted, setHasSaleStarted] = useState(false);
  const [currentSupply, setCurrentSupply] = useState<number>(0);
  const [supplyLimit, setSupplyLimit] = useState<number>(0);
  const [hasPresaleAllowance, setHasPresaleAllowance] = useState(false);
  const [presaleAllowance, setPresaleAllowance] = useState<number>(0);

  // Form state
  const [numBotsToBuy, setNumBotsToBuy] = useState<string>("1");

  // Function to actually do the minting
  const mintBOT = () => {
    return contract.methods.MINT_PRICE().call().then((currentPrice: number) => {
      const numBotsBuying: number = parseInt(numBotsToBuy)
      if (hasSaleStarted) {
        const gasLimit = 220000 * numBotsBuying - (((220000 * numBotsBuying) / 100) * (numBotsBuying - numBotsBuying * 0.2))
        return contract.methods.mintTokens(numBotsBuying).send(
          {
            from: accountAddress,
            value: currentPrice * numBotsBuying,
            gas: gasLimit
          })
          .on('transactionHash', (hash) => {
            setTransactionUrl("https://etherscan.io/tx/" + hash);
        })
      }
      const gasLimit = 240000 * numBotsBuying - (((240000 * numBotsBuying) / 100) * (numBotsBuying - numBotsBuying * 0.2))

      return contract.methods.mintPresaleTokens(numBotsBuying).send(
        {
          from: accountAddress,
          value: currentPrice * numBotsBuying,
          gas: gasLimit
        })
        .on('transactionHash', (hash) => {
          setTransactionUrl("https://etherscan.io/tx/" + hash);
      })
    })
  }

  // Sets initial price
  useEffect(() => {
    // Buy BOTs
    if (isLoading) {
      mintBOT().then(() => {
        setIsLoading(false)
      }).catch(e => {
        setIsLoading(false)
        setShouldShowError(true);
      });
    }
    if (contract) {
      contract.methods.totalSupply().call().then((supply: number) => {
        setCurrentSupply(supply)
      })

      contract.methods.TOKEN_LIMIT().call().then((botLimit: number) => {
        setSupplyLimit(botLimit)
      })

      contract.methods.isSaleActive().call().then((hasSaleStarted: boolean) => {
        setHasSaleStarted(hasSaleStarted)
      })

      contract.methods.hasPresaleStarted().call().then((hasPresaleStarted: boolean) => {
        setHasPresaleStarted(hasPresaleStarted)
      })

      if (accountAddress) {
        contract.methods.hasPresaleAllowance(accountAddress).call().then((hasPresaleAllowance: boolean) => {
          setHasPresaleAllowance(hasPresaleAllowance)
        })

        contract.methods.presaleAllowanceForAddress(accountAddress).call().then((presaleNumber: string) => {
          setPresaleAllowance(parseInt(presaleNumber))
        })
      }
    }
  }, [contract, isLoading, currentSupply, accountAddress]);

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsLoading(true);
  }

  const ethScanAddressLink = `https://etherscan.io/address/${accountAddress}`;
  const remainingBots = hasPresaleStarted ? String(supplyLimit - currentSupply) : "10000";
  const maxMintNumber = hasSaleStarted ? 20 : presaleAllowance;
  const isSoldOut = ((supplyLimit === currentSupply) && hasPresaleStarted);

  const { ethereum } = (typeof window !== 'undefined') ? window : { ethereum: null };
  const isMetaMaskInstalled = ethereum && ethereum.isMetaMask;
  const isMobileAndMetamaskNotInstalled = isMobile() && !isMetaMaskInstalled
  const isSaleActive = !isSoldOut && hasPresaleStarted
  const shouldShowMintButtonRow = (!isSoldOut && hasSaleStarted) || (hasPresaleStarted && hasPresaleAllowance && presaleAllowance > 0)
  const isEligibleToMint = hasSaleStarted || (hasPresaleStarted && presaleAllowance > 0);

  // Countdown timer logic
  const calculateTimeLeft = () => {
    const year = new Date().getFullYear();
    const difference = +new Date(`10 Oct 21 23:00:00 GMT`) - +new Date();
    // const difference = +new Date(`9 Oct 21 21:44:00 GMT`) - +new Date();
    let time = {};

    time = {
      hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((difference / 1000 / 60) % 60),
      seconds: Math.floor((difference / 1000) % 60),
      difference: difference
    }

    return time;
  }
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
    // Clear timeout if the component is unmounted
    return () => clearTimeout(timer);
  });

  const mintStatsText = remainingBots != 10000
    ? <p className={styles.mintStatsText}>{remainingBots}/10000</p>
    : <p className={styles.mintStatsText}>CONNECT TO SEE REMAINING MINTS</p>

  return (
    <>
      <Container className={`${styles.botSection} ${styles.welcomeHeader}`}>
        <Row>
          <Col className="d-flex flex-column align-items-center justify-content-center">
            <Image src={BotWelcomeHeader} className={styles.WelcomeHeaderImage} />
          </Col>
        </Row>
      </Container>

      <Container className={`${styles.botSection}`} style={{paddingTop: "0px"}}>
        <Row>
          <Col className={`d-flex flex-column align-items-start justify-content-center ${styles.welcomeSectionContent}`}>
            <h1 className={styles.welcomeTextHeader} style={{marginBottom: "0px"}}>WELCOME TO THE</h1>
            <h1 className={styles.welcomeTextHeader}>BIONIC OUTLIER TRIBE</h1>
            <h1 className={styles.alternateWelcomeTextHeader}>WELCOME TO THE BIONIC OUTLIER TRIBE</h1>
            <Row className="d-flex align-items-center justify-content-start">
              <Col className="col-12 col-sm-12 col-lg-7">
                <p className={styles.textContent}>Your journey through the Scavenge Lands begins here! Join Bionic Outlier Tribe and collect rare parts and crystals to become an ELITE!</p>
                <p className={styles.textContent}>When you enter the Scavenge Lands there’s no such thing as safety. If you join a tribe, you hunt in numbers collecting parts that will bring status and power. The elites have the luxury to trade in crystal instead of their own bodies but even the most elite tribes have no loyalty to each other. The 16 pack leaders used to control corners of this wasteland now their parts are scattered throughout.</p>
                <p className={styles.textContent}>Will you be the first to claim a “Pack Leader”? Hunt alone and you will never be betrayed, but a solo scavenger must be more ruthless than anyone else to survive. Are you a survivor?</p>
              </Col>
              <Col className="col-12 col-sm-12 col-lg-5 d-flex align-items-center justify-content-end">
                <Image src={BOTWelcome} className={styles.botHomeAboutImage} />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>

      <Container>
        <Row>
          <Col>
            <hr className={styles.lineBreak} />
          </Col>
        </Row>
      </Container>

      <Container id="mint" className={`d-flex align-items-center justify-content-center ${styles.botSection}`} fluid>
        <Row className="mint-row">
          <Col className="col-12 col-lg-6 d-flex align-items-end justify-content-center">
            <Image src={BOTGif} className={styles.homePageImage} />
          </Col>
          <Col className={`col-12 col-lg-6 d-flex flex-column justify-content-center`}>
            <Row>
              <Col className="d-flex align-items-center justify-content-center">
                <Image src={BotLogo} className={styles.mintBotLogo} />
              </Col>
            </Row>
            {(!accountAddress) && (
              <Row>
                <Col className="d-flex align-items-center justify-content-center">
                  <div className={styles.metamaskConnect}>
                    <MetaMaskConnectButton classNames={styles.metamaskConnectButton} getAndSetAccount={() => getAndSetAccount()} />
                  </div>
                </Col>
              </Row>
            )}
            {(!isMobileAndMetamaskNotInstalled && accountAddress && shouldShowMintButtonRow) && (
              <Row className={styles.mintButtonRow}>
                <Form className="d-flex align-items-center justify-content-center" noValidate onSubmit={onSubmit}>
                  <Col className="d-flex align-items-center justify-content-center flex-wrap">
                    <Button
                      type="submit"
                      variant="primary"
                      className={styles.mintButton}
                      disabled={!isSaleActive}
                    >
                      MINT
                    </Button>
                    <Form.Control
                      as="select"
                      className={styles.mintInput}
                      value={numBotsToBuy}
                      disabled={!isSaleActive}
                      onChange={({ target: { value } }) => {
                        setNumBotsToBuy(value);
                      }}
                    >
                      {[...Array(maxMintNumber).keys()].map((index) => {
                        const number = index + 1
                        return <option key={number} value={number.toString()}>{number}</option>
                      })}
                    </Form.Control>
                    <p
                      className={styles.maxMintText}
                      onClick={() => {
                        if (!isSoldOut) {
                          setNumBotsToBuy(`${maxMintNumber}`)
                        }
                      }}
                    >
                      MAX
                    </p>
                  </Col>
                </Form>
              </Row>
            )}
            {(!isMobileAndMetamaskNotInstalled && accountAddress && !shouldShowMintButtonRow) && (
              <Row>
                <Col className="d-flex align-items-center justify-content-center">
                  <div className={styles.metamaskConnect}>
                    <Button
                      variant="primary"
                      className={styles.comingSoonButton}
                    >
                      MINTING PAUSED
                    </Button>
                  </div>
                </Col>
              </Row>
            )}
            {(transactionUrl !== "") && (
              <Row className="d-flex align-items-center justify-content-center">
                <Col className="d-flex align-items-center justify-content-center">
                  <a href={transactionUrl} target="_blank" className={styles.transactionLink}>View transaction</a>
                </Col>
              </Row>
            )}
            <Row>
              <Col className="d-flex align-items-center justify-content-center">
                {mintStatsText}
              </Col>
            </Row>
            <Row>
              <Col className="d-flex justify-content-center">
                <p className={styles.mintStatsText}>0.1 ETH</p>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>

      <Container>
        <Row>
          <Col>
            <hr className={styles.lineBreak} />
          </Col>
        </Row>
      </Container>

      <Container id="about" className={styles.botSection}>
        <Row>
          <Col>
            <Row className="d-flex align-items-center justify-content-center">
              <Col className="col-12 col-sm-12 col-lg-7">
                <h1 className={styles.textHeader}>RARITY</h1>
                  <p className={styles.textContent}>Each Bionic Outlier is unique and programmatically generated from hundreds of possible traits, including eyes, jaw, headwear, clothing, and more.</p>
                  <p className={styles.textContent}>All Bionic Outliers have their own unique set of traits, but some traits are rarer than others.</p>
                  <p className={styles.textContent}>The Bionic Outliers are stored as ERC-721 tokens on the Ethereum blockchain and hosted on IPFS. Purchasing a BOT costs 0.1 ETH.</p>
              </Col>
              <Col className="col-0 col-sm-0 col-lg-1"></Col>
              <Col className="col-12 col-sm-12 col-lg-4 d-flex align-items-center justify-content-start flex-wrap">
                <Row className="d-flex align-items-center justify-content-start flex-wrap">
                  <Col>
                    <video
                      width="100%"
                      height="100%"
                      src={BotGold}
                      className={styles.botHomeSpecsImage}
                      style={{marginBottom: "1.1rem"}}
                      playsInline={true}
                      loop={true}
                      autoPlay={true}
                      muted={true}
                    >
                      <source src={BotGold} type="video/mp4" />
                    </video>
                  </Col>
                  <Col>
                    <Image src={Bot2} className={styles.botHomeSpecsImage} />
                  </Col>
                </Row>
                <Row className="d-flex align-items-center justify-content-start flex-wrap">
                  <Col>
                    <Image src={Bot3} className={styles.botHomeSpecsImage} />
                  </Col>
                  <Col>
                    <Image src={Bot4} className={styles.botHomeSpecsImage} />
                  </Col>
                </Row>
                <Row className="d-flex align-items-center justify-content-start flex-wrap">
                  <Col>
                    <Image src={Bot5} className={styles.botHomeSpecsImage} />
                  </Col>
                  <Col>
                    <Image src={Bot6} className={styles.botHomeSpecsImage} />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>

      <Container>
        <Row>
          <Col>
            <hr className={styles.lineBreak} />
          </Col>
        </Row>
      </Container>

      <Container className={styles.botSection}>
        <Row className={styles.tribeContent}>
          <Col className="col-12 col-sm-12 col-lg-7 d-flex flex-column align-items-start justify-content-center">
            <h1 className={styles.textHeader}>TRIBE INITIATION</h1>
            <p className={styles.textContent}>When purchasing a Bionic Outlier, you are initiated into a Bionic Outlier Tribe and enter the Scavenge Lands, a desolate area within the metaverse. Work alongside tribe members collecting the rarest parts and crystals to grow stronger together or venture solo -  but be warned, taking on this journey alone will not be an easy task. As the story of Bionic Outlier Tribe unveils, your tribe will play an important role. To access the BOT’s only area, you will need to hold a BOT NFT in your Metamask wallet.</p>
          </Col>
          <Col className="col-12 col-sm-12 col-lg-5 d-flex align-items-center justify-content-center"></Col>
        </Row>
        <Row className="d-flex align-items-center justify-content-center flex-wrap">
          <Col className="d-flex flex-column align-items-center justify-content-center col-6 col-md-3">
            <Image src={Badge1} className={styles.badgeImage} />
            <p className={styles.badgeHeader}>Morphic</p>
            <p className={styles.badgeContent}>Collectors of only the strongest, most durable parts. This tribe values strength and stability.</p>
          </Col>
          <Col className="d-flex flex-column align-items-center justify-content-center col-6 col-md-3">
            <Image src={Badge2} className={styles.badgeImage} />
            <p className={styles.badgeHeader}>Piston</p>
            <p className={styles.badgeContent}>Reckless daredevils, who value speed above all else; commonly found racing one another in deadly “winner takes all” races.</p>
          </Col>
          <Col className="d-flex flex-column align-items-center justify-content-center col-6 col-md-3">
            <Image src={Badge3} className={styles.badgeImage} />
            <p className={styles.badgeHeader}>Gear</p>
            <p className={styles.badgeContent}>Skilled mechanics, who trade in upgrades and body maintenance.</p>
          </Col>
          <Col className="d-flex flex-column align-items-center justify-content-center col-6 col-md-3">
            <Image src={Badge4} className={styles.badgeImage} />
            <p className={styles.badgeHeader}>Astro</p>
            <p className={styles.badgeContent}>Ambitious explorers, unified by the common goal of escaping the Scavenge Lands, and taking to the stars.</p>
          </Col>
        </Row>
        <Row className="d-flex align-items-center justify-content-center flex-wrap">
          <Col className="d-flex flex-column align-items-center justify-content-center col-6 col-md-3">
            <Image src={Badge5} className={styles.badgeImage} />
            <p className={styles.badgeHeader}>Shrapnel</p>
            <p className={styles.badgeContent}>Vicious warriors who tear through anyone who crosses their path for parts. If you are not one of them, you are their prey.</p>
          </Col>
          <Col className="d-flex flex-column align-items-center justify-content-center col-6 col-md-3">
            <Image src={Badge6} className={styles.badgeImage} />
            <p className={styles.badgeHeader}>Punk</p>
            <p className={styles.badgeContent}>Dangerous anarchists who seek to destroy the Tribe factions from within. They disguise themselves as other Tribe members, until their true motives can be revealed.</p>
          </Col>
          <Col className="d-flex flex-column align-items-center justify-content-center col-6 col-md-3">
            <Image src={Badge7} className={styles.badgeImage} />
            <p className={styles.badgeHeader}>Beast</p>
            <p className={styles.badgeContent}>Lawless nomads, here to party and make a mess. They spit in the face of any organized group that stands in the way of their chaotic antics.</p>
          </Col>
          <Col className="d-flex flex-column align-items-center justify-content-center col-6 col-md-3">
            <Image src={Badge8} className={styles.badgeImage} />
            <p className={styles.badgeHeader}>Flesh</p>
            <p className={styles.badgeContent}>Uncanny biohackers who stretch the limits of ways they can modify and change their bodies, with some very mixed results.</p>
          </Col>
        </Row>
        <Row className="d-flex align-items-center justify-content-center flex-wrap">
          <Col className="d-flex flex-column align-items-center justify-content-center col-6 col-md-3">
            <Image src={Badge9} className={styles.badgeImage} />
            <p className={styles.badgeHeader}>Nuclear</p>
            <p className={styles.badgeContent}>Fearless occupants of the Scavenge Land’s most treacherous terrains. They use their knowledge of destructive materials and locations to their advantage.</p>
          </Col>
          <Col className="d-flex flex-column align-items-center justify-content-center col-6 col-md-3">
            <Image src={Badge10} className={styles.badgeImage} />
            <p className={styles.badgeHeader}>Cyber</p>
            <p className={styles.badgeContent}>Idealistic pacifists, dedicated to achieving a digital singularity, where they can escape to a more peaceful cyberspace.</p>
          </Col>
          <Col className="d-flex flex-column align-items-center justify-content-center col-6 col-md-3">
            <Image src={Badge11} className={styles.badgeImage} />
            <p className={styles.badgeHeader}>Circuit</p>
            <p className={styles.badgeContent}>Skilled hackers who weaponize code and software, instead of using direct force.</p>
          </Col>
          <Col className="d-flex flex-column align-items-center justify-content-center col-6 col-md-3">
            <Image src={Badge12} className={styles.badgeImage} />
            <p className={styles.badgeHeader}>Freight</p>
            <p className={styles.badgeContent}>Wealthy smugglers who make their fortune from collecting, stealing, and trading coveted parts.</p>
          </Col>
        </Row>
        <Row className="d-flex align-items-center justify-content-center flex-wrap">
          <Col className="d-flex flex-column align-items-center justify-content-center col-6 col-md-3">
            <Image src={Badge13} className={styles.badgeImage} />
            <p className={styles.badgeHeader}>Byte</p>
            <p className={styles.badgeContent}>Hoarders of data and information who wield their knowledge for power. They are the only tribe with a comprehensive knowledge of locations and resources within the Scavenge Lands.</p>
          </Col>
          <Col className="d-flex flex-column align-items-center justify-content-center col-6 col-md-3">
            <Image src={Badge14} className={styles.badgeImage} />
            <p className={styles.badgeHeader}>Nautical</p>
            <p className={styles.badgeContent}>Powerful inhabitants of the undersea realms, they have gained wealth and resources by scavenging the ocean depths for artifacts from the Old World.</p>
          </Col>
          <Col className="d-flex flex-column align-items-center justify-content-center col-6 col-md-3">
            <Image src={Badge15} className={styles.badgeImage} />
            <p className={styles.badgeHeader}>Angel</p>
            <p className={styles.badgeContent}>The most skilled mechanics and engineers, who perform the most advanced upgrades, and can fix almost any form of damage, at a very high price. Generally peaceful, until you fall into their debt.</p>
          </Col>
          <Col className="d-flex flex-column align-items-center justify-content-center col-6 col-md-3 bot-tribe-card">
            <Image src={Badge16} className={styles.badgeImage} />
            <p className={styles.badgeHeader}>Acid</p>
            <p className={styles.badgeContent}>Brilliant chemists who dedicate themselves to creating and harnessing powerful forms of energy and fuel.</p>
          </Col>
        </Row>
      </Container>

      <Container>
        <Row>
          <Col>
            <hr className={styles.lineBreak} />
          </Col>
        </Row>
      </Container>

      <Container id="roadmap" className={styles.botSection}>
        <Row>
          <Col>
            <h1 className={styles.textHeader}>ROADMAP</h1>
          </Col>
        </Row>
        <Row>
          <Col className="col-0 col-sm-0 col-lg-2"></Col>
          <Col className="col-12 col-sm-12 col-lg-8 d-flex flex-column align-items-start justify-content-center">
            <Row className={styles.roadmapRow}>
              <Col className="col-3 col-sm-3 col-lg-2 m-auto d-flex align-items-center justify-content-center">
                <Image src={Crystal1} className={styles.roadmapCrystalImage} style={{marginRight: "10px"}} />
                <p className={styles.roadmapPercentage}>25%</p>
              </Col>
              <Col className="col-9 col-sm-9 col-lg-10 m-auto">
                <p>
                  All BOT owners will get a Purple crystal to start
                  the Gamification process. The crystals will have
                  the codes to unlock the website embedded in
                  them - a code to a letter cypher and you need to
                  unscramble the word puzzle to unlock the
                  password box on the website.
                </p>
              </Col>
            </Row>

            <h2 className={styles.roadmapHeader}>The Beginning</h2>
            <Row className={styles.roadmapRow}>
              <Col className="col-3 col-sm-3 col-lg-2 m-auto d-flex align-items-center justify-content-center">
                <Image src={Crystal2} className={styles.roadmapCrystalImage} style={{marginRight: "10px"}} />
                <Image src={Crystal3} className={styles.roadmapCrystalImage} />
              </Col>
              <Col className="col-9 col-sm-9 col-lg-10 m-auto">
                <p>
                  Browser-based scavenger hunt is
                  launched to locate the Red and Blue
                  Crystals. The Crystals will be a claimable
                  free mint + gas. The Red and Blue
                  Crystals will be burned for an
                  upgraded Bot (Red or Blue), which will
                  reduce the 16 Tribes down to 2 teams.
                </p>
              </Col>

              <div className="d-flex align-items-center justify-content-center">
                <Image src={RoadmapBot1} className={styles.roadmapBotCrystalImage} />
                <span className={styles.roadmapEquation}>+</span>
                <Image src={Crystal2} className={styles.roadmapBotCrystalImage} />
                <span className={styles.roadmapEquation}>=</span>
                <span className={styles.roadmapEquation}>?</span>
              </div>
              <div className="d-flex align-items-center justify-content-center">
                <Image src={RoadmapBot2} className={styles.roadmapBotCrystalImage} />
                <span className={styles.roadmapEquation}>+</span>
                <Image src={Crystal3} className={styles.roadmapBotCrystalImage} />
                <span className={styles.roadmapEquation}>=</span>
                <span className={styles.roadmapEquation}>?</span>
              </div>
            </Row>

            <Row className={styles.roadmapRow}>
              <Col className="col-3 col-sm-3 col-lg-2 m-auto">
                <p className={styles.roadmapPercentage}>50%</p>
              </Col>
              <Col className="col-9 col-sm-9 col-lg-10 m-auto">
                <p>30 BOTs Giveaway.</p>
              </Col>
            </Row>
            <Row className={styles.roadmapRow}>
              <Col className="col-3 col-sm-3 col-lg-2 m-auto">
                <p className={styles.roadmapPercentage}>75%</p>
              </Col>
              <Col className="col-9 col-sm-9 col-lg-10 m-auto">
                <p style={{ marginBottom: "0px" }}>Merch Store unlocks: Limited supply, 1st come 1st serve for BOTs owners.</p>
                <p>Teasers for the upcoming Metaverse Game. Get to know and understand how to dominate the Scavengelands.</p>
              </Col>
            </Row>
            <Row className={styles.roadmapRow}>
              <Col className="col-3 col-sm-3 col-lg-2 m-auto">
                <p className={styles.roadmapPercentage}>100%</p>
              </Col>
              <Col className="col-9 col-sm-9 col-lg-10 m-auto">
                <p>
                  Let The Games Begin. Holders of the Red
                  and Blue Crystals will fight each other in
                  a turn-based RPG (DnD style) game
                  played in the Metaverse. The fight will be
                  determined by picking different attacks
                  and or maneuver moves, which will be
                  weighted where Elites have more
                  options.
                </p>
              </Col>
            </Row>

            <h2 className={styles.roadmapHeader}>Final Stage</h2>
            <Row className={styles.roadmapRow}>
              <Col className="col-3 col-sm-3 col-lg-2 m-auto d-flex align-items-center justify-content-center">
                <Image src={Crystal4} className={styles.roadmapCrystalImage} />
              </Col>
              <Col className="col-9 col-sm-9 col-lg-10 m-auto">
                <p>
                  Winning team will get the Green Final Crystal. You will be able to burn your Green Crystal
                  for the Final Upgraded BOT (a collection of 5k potentially).
                </p>
              </Col>
            </Row>
          </Col>
          <Col className="col-0 col-sm-0 col-lg-2"></Col>
        </Row>
      </Container>

      <Container>
        <Row>
          <Col>
            <hr className={styles.lineBreak} />
          </Col>
        </Row>
      </Container>

      <Container id="team" className={styles.botSection}>
        <Row>
          <Col>
            <h1 className={styles.textHeader}>THE TEAM</h1>
          </Col>
        </Row>
        <Row className="d-flex align-items-center justify-content-center">
          <Col className="col-12 col-sm-12 col-lg-2">
            <Image src={Team1} />
          </Col>
          <Col className="col-12 col-sm-12 col-lg-4">
            <div className="d-flex flex-column align-items-start justify-content-around">
              <p className={styles.textContent}>WSAVAS</p>
              <p className={styles.textContent}>Savage Chief. Collector of rare parts. NFT degen.</p>
            </div>
          </Col>
          <Col className="col-12 col-sm-12 col-lg-2">
            <Image src={Team2} />
          </Col>
          <Col className="col-12 col-sm-12 col-lg-4">
            <div className="d-flex flex-column align-items-start justify-content-around">
              <p className={styles.textContent}>ST</p>
              <p className={styles.textContent}>Scavenger Hunter. Crystal seeker. Consuming copious amounts of Coconut La Croix.</p>
            </div>
          </Col>
        </Row>
        <Row className="d-flex align-items-center justify-content-center">
          <Col className="col-12 col-sm-12 col-lg-2">
            <Image src={Team3} />
          </Col>
          <Col className="col-12 col-sm-12 col-lg-4">
            <div className="d-flex flex-column align-items-start justify-content-around">
              <p className={styles.textContent}>BEAR</p>
              <p className={styles.textContent}>Cyborg coordinator and curator of crystals. A Scavenge Land property owner.</p>
            </div>
          </Col>
          <Col className="col-12 col-sm-12 col-lg-2">
            <Image src={Team4} />
          </Col>
          <Col className="col-12 col-sm-12 col-lg-4">
            <div className="d-flex flex-column align-items-start justify-content-around">
              <p className={styles.textContent}>ROCKET FACE</p>
              <p className={styles.textContent}>Mechanic extraordinaire. Will add rockets to any surfaces that don’t have rockets.</p>
            </div>
          </Col>
        </Row>
        <Row className="d-flex align-items-center justify-content-center">
          <Col className="col-12 col-sm-12 col-lg-2">
            <Image src={Team5} />
          </Col>
          <Col className="col-12 col-sm-12 col-lg-4">
            <div className="d-flex flex-column align-items-start justify-content-around">
              <p className={styles.textContent}>JF</p>
              <p className={styles.textContent}>Scavenger and collector of lost parts. No one's taking my gas mask.</p>
            </div>
          </Col>
          <Col className="col-12 col-sm-12 col-lg-2">
            <Image src={Team6} />
          </Col>
          <Col className="col-12 col-sm-12 col-lg-4">
            <div className="d-flex flex-column align-items-start justify-content-around">
              <p className={styles.textContent}>OCB</p>
              <p className={styles.textContent}>Centralized tribe building decentralized BOTs. We know the best pizza joint in the Scavenge Land.</p>
            </div>
          </Col>
          <Col className="col-12 col-sm-12 col-lg-6"></Col>
        </Row>
      </Container>

      <Container className={styles.botSection}>
        <Row>
          <Col className="d-flex align-items-center justify-content-center">
            <p>VERIFIED SMART CONTRACT ADDRESS: <a className={styles.contractLink} href="https://etherscan.io/address/0xda05ce4a985d2aa058382877e08fb38bd649fb60" target="_blank">0xda05ce4a985d2aa058382877e08fb38bd649fb60</a></p>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default Home
